  $(function () {
    jQuery.validator.addMethod(
      "zenkaku_kana",
      function (value, element) {
        return this.optional(element) || /^[ァ-ンヴー]*$/.test(value);
      },
      "全角カナで入力してください"
    );
    jQuery.validator.addMethod(
      "zenkaku",
      function (value, element) {
        return this.optional(element) || /^[^\x20-\x7e]*$/.test(value);
      },
      "全角で入力してください"
    );
    jQuery.validator.addMethod(
      "only_number",
      function (value, element) {
        return this.optional(element) || /^[0-9]+$/.test(value);
      },
      "半角数字で入力してください"
    );
    jQuery.validator.addMethod(
      "zip_code",
      function (value, element) {
        return this.optional(element) || /^\d{7}$/.test(value);
      },
      "郵便番号の形式で入力してください"
    );
    jQuery.validator.addMethod(
      "webbill_id_19",
      function (value, element) {
        return this.optional(element) || /^\d{17}$/.test(value);
      },
      "郵便番号の形式で入力してください"
    );
    jQuery.validator.addMethod(
      "number_10",
      function (value, element) {
        return this.optional(element) || /^\d{10}$/.test(value);
      },
      "郵便番号の形式で入力してください"
    );
    jQuery.validator.addMethod(
      "number_4",
      function (value, element) {
        return this.optional(element) || /^\d{4}$/.test(value);
      },
      "4桁の数字で入力してください"
    );
    jQuery.validator.addMethod(
      "number_3",
      function (value, element) {
        return this.optional(element) || /^\d{3}$/.test(value);
      },
      "3桁の数字で入力してください"
    );
    jQuery.validator.addMethod(
      "number_8",
      function (value, element) {
        return this.optional(element) || /^\d{8}$/.test(value);
      },
      "8桁の数字で入力してください"
    );
    $("#cancel_order_form").validate({
      rules: {
        "cancel_order[cancel_order_quit_info_attributes][quit_type]": {
          required: true,
        },
        "cancel_order[cancel_order_quit_info_attributes][preferred_quit_date]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=cancel_order_cancel_order_quit_info_attributes_quit_type_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "cancel_order[cancel_order_quit_info_attributes][removal]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_quit_info_attributes_quit_type_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "cancel_order[cancel_order_retrieve_info_attributes][destination_flg]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=cancel_order_cancel_order_quit_info_attributes_quit_type_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "cancel_order[cancel_order_retrieve_info_attributes][last_name_kana]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku_kana: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "cancel_order[cancel_order_retrieve_info_attributes][first_name_kana]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku_kana: {
              depends: function () {
                if (
                  $(
                    "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "cancel_order[cancel_order_retrieve_info_attributes][first_name]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "cancel_order[cancel_order_retrieve_info_attributes][last_name]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "cancel_order[cancel_order_retrieve_info_attributes][address_zip_code]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zip_code: {
              depends: function () {
                if (
                  $(
                    "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "cancel_order[cancel_order_retrieve_info_attributes][address_prefecture]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku: {
              depends: function () {
                if (
                  $(
                    "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "cancel_order[cancel_order_retrieve_info_attributes][address_city]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "cancel_order[cancel_order_retrieve_info_attributes][address_town]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "cancel_order[cancel_order_retrieve_info_attributes][tel]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          only_number: {
            depends: function () {
              if (
                $(
                  "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
      },
      messages: {
        "cancel_order[cancel_order_quit_info_attributes][quit_type]": {
          required: "選択して下さい",
        },
        "cancel_order[cancel_order_quit_info_attributes][preferred_quit_date]":
          {
            required: "解約される場合は必須項目です",
          },
        "cancel_order[cancel_order_quit_info_attributes][removal]": {
          required: "解約される場合は必須項目です",
        },
        "cancel_order[cancel_order_retrieve_info_attributes][destination_flg]":
          {
            required: "解約される場合は必須項目です",
          },
        "cancel_order[cancel_order_retrieve_info_attributes][last_name_kana]": {
          required: "集荷先が異なる場合は必須項目です",
        },
        "cancel_order[cancel_order_retrieve_info_attributes][first_name_kana]":
          {
            required: "集荷先が異なる場合は必須項目です",
          },
        "cancel_order[cancel_order_retrieve_info_attributes][last_name_kana]": {
          required: "集荷先が異なる場合は必須項目です",
        },
        "cancel_order[cancel_order_retrieve_info_attributes][first_name]": {
          required: "集荷先が異なる場合は必須項目です",
        },
        "cancel_order[cancel_order_retrieve_info_attributes][last_name]": {
          required: "集荷先が異なる場合は必須項目です",
        },
        "cancel_order[cancel_order_retrieve_info_attributes][address_zip_code]":
          {
            required: "集荷先が異なる場合は必須項目です",
          },
        "cancel_order[cancel_order_retrieve_info_attributes][address_prefecture]":
          {
            required: "集荷先が異なる場合は必須項目です",
          },
        "cancel_order[cancel_order_retrieve_info_attributes][address_city]": {
          required: "集荷先が異なる場合は必須項目です",
        },
        "cancel_order[cancel_order_retrieve_info_attributes][address_town]": {
          required: "集荷先が異なる場合は必須項目です",
        },
        "cancel_order[cancel_order_retrieve_info_attributes][tel]": {
          required: "集荷先が異なる場合は必須項目です",
        },
      },
      errorPlacement: function (error, element) {
        if (
          element.attr("name") ==
          "cancel_order[cancel_order_quit_info_attributes][quit_type]"
        ) {
          error.insertAfter("#validation_place");
        } else if (
          element.attr("name") ==
          "cancel_order[cancel_order_quit_info_attributes][removal]"
        ) {
          error.insertAfter("#validation_place2");
        } else if (
          element.attr("name") ==
          "cancel_order[cancel_order_retrieve_info_attributes][destination_flg]"
        ) {
          error.insertAfter("#validation_place3");
        } else {
          error.insertAfter(element);
        }
      },
    });
    jQuery.validator.addMethod(
      "zenkaku_kana",
      function (value, element) {
        return this.optional(element) || /^[ァ-ンヴー]*$/.test(value);
      },
      "全角カナで入力してください"
    );
    jQuery.validator.addMethod(
      "zenkaku",
      function (value, element) {
        return this.optional(element) || /^[^\x20-\x7e]*$/.test(value);
      },
      "全角で入力してください"
    );
    jQuery.validator.addMethod(
      "only_number",
      function (value, element) {
        return this.optional(element) || /^[0-9]+$/.test(value);
      },
      "半角数字で入力してください"
    );
    jQuery.validator.addMethod(
      "number_symbol_text",
      function (value, element) {
        return this.optional(element) || /^[ -~]+$/.test(value);
      },
      "半角英数字記号で入力してください"
    );
    jQuery.validator.addMethod(
      "email_address",
      function (value, element) {
        return (
          this.optional(element) ||
          /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/.test(
            value
          )
        );
      },
      "メールアドレスの形式で入力してください"
    );
    jQuery.validator.addMethod(
      "zip_code",
      function (value, element) {
        return this.optional(element) || /^\d{7}$/.test(value);
      },
      "郵便番号の形式で入力してください"
    );
    $("#new_order_form").validate({
      errorElement: "div", // 指定しない場合のデフォルトは「label」
      rules: {
        "new_order[webbill_status]": {
          required: true,
        },
        // "new_order[webbill_id]": {
        //   only_number: true,
        //   webbill_id_19: true,
        // },
        "new_order[webbill_id_1]": {
          number_4: true,
          required: {
            depends: function(element) {
              return $("#new_order_webbill_id_2").val() !== "" || $("#new_order_webbill_id_3").val() !== "" || $("#new_order_webbill_id_4").val() !== "";
            }
          },
        },
        "new_order[webbill_id_2]": {
          number_3: true,
          required: {
            depends: function(element) {
              return $("#new_order_webbill_id_1").val() !== "" || $("#new_order_webbill_id_3").val() !== "" || $("#new_order_webbill_id_4").val() !== "";
            }
          },
        },
        "new_order[webbill_id_3]": {
          number_8: true,
          required: {
            depends: function(element) {
              return $("#new_order_webbill_id_1").val() !== "" || $("#new_order_webbill_id_2").val() !== "" || $("#new_order_webbill_id_4").val() !== "";
            }
          },
        },
        "new_order[webbill_id_4]": {
          number_4: true,
          required: {
            depends: function(element) {
              return $("#new_order_webbill_id_1").val() !== "" || $("#new_order_webbill_id_2").val() !== "" || $("#new_order_webbill_id_3").val() !== "";
            }
          },
        },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][last_name_kana]":
          {
            required: true,
            zenkaku_kana: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][first_name_kana]":
          {
            required: true,
            zenkaku_kana: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][first_name]":
          {
            required: true,
            zenkaku: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][last_name]":
          {
            required: true,
            zenkaku: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][gender]":
          {
            required: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][address_zip_code]":
          {
            required: true,
            zip_code: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][address_prefecture]":
          {
            required: true,
            zenkaku: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][address_city]":
          {
            required: true,
            zenkaku: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][address_town]":
          {
            required: true,
            zenkaku: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][address_optional]":
          {
            required: true,
            zenkaku: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][birth_day]":
          {
            required: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][tel]":
          {
            required: true,
            only_number: true,
          },
        "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][tel_daytime]":
          {
            required: true,
            only_number: true,
          },
        "new_order[new_order_building_info_attributes][address_zip_code]": {
          required: true,
          zip_code: true,
        },
        "new_order[new_order_building_info_attributes][address_prefecture]": {
          required: true,
          zenkaku: true,
        },
        "new_order[new_order_building_info_attributes][address_city]": {
          required: true,
          zenkaku: true,
        },
        "new_order[new_order_building_info_attributes][address_town]": {
          required: true,
          zenkaku: true,
        },
        "new_order[new_order_building_info_attributes][address_optional]": {
          required: true,
          zenkaku: true,
        },
        "new_order[new_order_building_info_attributes][email]": {
          required: true,
          email_address: true,
        },
        // "new_order[new_order_construction_reservation_attributes][pre_construction_reservation_time_zone]":
        //   {
        //     required: {
        //       depends: function () {
        //         var date = $(
        //           "#new_order_new_order_construction_reservation_attributes_pre_construction_reservation_date"
        //         ).val();
        //         if (date != "") {
        //           return true;
        //         } else {
        //           return false;
        //         }
        //       },
        //     },
        //   },
        // "new_order[new_order_construction_reservation_attributes][prefer_same_day]":
        //   {
        //     required: {
        //       depends: function () {
        //         var date = $(
        //           "#new_order_new_order_construction_reservation_attributes_pre_construction_reservation_date"
        //         ).val();
        //         if (date != "") {
        //           return true;
        //         } else {
        //           return false;
        //         }
        //       },
        //     },
        //   },
        "new_order[new_order_payment_info_attributes][payment_type]": {
          required: true,
        },
        "new_order[new_order_concurrent_construction_attributes][construction]":
          {
            required: true,
          },
        "new_order[new_order_concurrent_construction_attributes][flets_customer_id]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_concurrent_construction_attributes_construction_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
            number_symbol_text: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_concurrent_construction_attributes_construction_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_concurrent_construction_attributes][current_voip_type]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_concurrent_construction_attributes_construction_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_concurrent_construction_attributes][voip_name]": {
          required: {
            depends: function () {
              const selected = $("#concurrent_construction_select").val();
              if (selected === "01") {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku: {
            depends: function () {
              const selected = $("#concurrent_construction_select").val();
              if (selected === "01") {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_concurrent_construction_attributes][voip_tel]": {
          required: {
            depends: function () {
              const selected = $("#concurrent_construction_select").val();
              if (selected === "01") {
                return true;
              } else {
                return false;
              }
            },
          },
          only_number: {
            depends: function () {
              const selected = $("#concurrent_construction_select").val();
              if (selected === "01") {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][last_name_kana]":
          {
            required: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku_kana: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][first_name_kana]":
          {
            required: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku_kana: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][last_name]":
          {
            required: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][first_name]":
          {
            required: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][address_zip_code]":
          {
            required: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zip_code: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][address_prefecture]":
          {
            required: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][address_city]":
          {
            required: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][address_town]":
          {
            required: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][tel]":
          {
            required: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
            only_number: {
              depends: function () {
                const selected = $("#payment_info_select").val();
                if (selected === "04" || selected === "05") {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][zeroabj_source]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_use_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_option_info_voip_attributes][use]": {
          required: true,
        },
        "new_order[new_order_option_info_hikari_tvs_attributes][0][use]": {
          required: true,
        },
        "new_order[new_order_option_info_remote_support_attributes][use]": {
          required: true,
        },
        "new_order[new_order_option_info_mcafee_security_attributes][use]": {
          required: true,
        },
        "new_order[new_order_option_info_voip_attributes][number_portability0abj_number]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
            only_number: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
            number_10: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][option_number_notice]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_use_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][option_number_display]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_use_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][option_number_request]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_use_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][option_catch_phone]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_use_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][option_voice_warp]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_use_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_option_info_voip_attributes][option_stop_nuisance_call]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_use_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][option_service_pack]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_use_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][option_white_call]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_option_info_voip_attributes][new_order_option_info_voip_option_white_call_targets_attributes][0][last_name_kana]":
          {
            required: {
              depends: function () {
                var value = $(
                  "#new_order_new_order_option_info_voip_attributes_new_order_option_info_voip_option_white_call_targets_attributes_0_tel"
                ).val();
                if (value) {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku_kana: {
              depends: function () {
                var value = $(
                  "#new_order_new_order_option_info_voip_attributes_new_order_option_info_voip_option_white_call_targets_attributes_0_tel"
                ).val();
                if (value) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][new_order_option_info_voip_option_white_call_targets_attributes][0][first_name_kana]":
          {
            required: {
              depends: function () {
                var value = $(
                  "#new_order_new_order_option_info_voip_attributes_new_order_option_info_voip_option_white_call_targets_attributes_0_tel"
                ).val();
                if (value) {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku_kana: {
              depends: function () {
                var value = $(
                  "#new_order_new_order_option_info_voip_attributes_new_order_option_info_voip_option_white_call_targets_attributes_0_tel"
                ).val();
                if (value) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][new_order_option_info_voip_option_white_call_targets_attributes][0][last_name]":
          {
            required: {
              depends: function () {
                var value = $(
                  "#new_order_new_order_option_info_voip_attributes_new_order_option_info_voip_option_white_call_targets_attributes_0_tel"
                ).val();
                if (value) {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku: {
              depends: function () {
                var value = $(
                  "#new_order_new_order_option_info_voip_attributes_new_order_option_info_voip_option_white_call_targets_attributes_0_tel"
                ).val();
                if (value) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][new_order_option_info_voip_option_white_call_targets_attributes][0][first_name]":
          {
            required: {
              depends: function () {
                var value = $(
                  "#new_order_new_order_option_info_voip_attributes_new_order_option_info_voip_option_white_call_targets_attributes_0_tel"
                ).val();
                if (value) {
                  return true;
                } else {
                  return false;
                }
              },
            },
            zenkaku: {
              depends: function () {
                var value = $(
                  "#new_order_new_order_option_info_voip_attributes_new_order_option_info_voip_option_white_call_targets_attributes_0_tel"
                ).val();
                if (value) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][new_order_option_info_voip_option_white_call_targets_attributes][0][relation]":
          {
            required: {
              depends: function () {
                var value = $(
                  "#new_order_new_order_option_info_voip_attributes_new_order_option_info_voip_option_white_call_targets_attributes_0_tel"
                ).val();
                if (value) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][last_name_kana]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku_kana: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_option_info_voip_attributes][first_name_kana]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku_kana: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_option_info_voip_attributes][last_name]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_option_info_voip_attributes][first_name]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_option_info_voip_attributes][current_vo_ip_code]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_option_info_voip_attributes][subscriber_same_flag]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[current_vo_ip_subscriber_name_kana_1]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_subscriber_same_flag_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku_kana: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_subscriber_same_flag_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[current_vo_ip_subscriber_name_kana_2]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_subscriber_same_flag_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku_kana: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_subscriber_same_flag_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[current_vo_ip_subscriber_name_1]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_subscriber_same_flag_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_subscriber_same_flag_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[current_vo_ip_subscriber_name_2]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_subscriber_same_flag_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
          zenkaku: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_voip_attributes_subscriber_same_flag_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_option_info_hikari_tvs_attributes][0][plan]": {
          required: {
            depends: function () {
              if (
                $(
                  "[id=new_order_new_order_option_info_hikari_tvs_attributes_0_use_1]"
                ).prop("checked")
              ) {
                return true;
              } else {
                return false;
              }
            },
          },
        },
        "new_order[new_order_option_info_hikari_tvs_attributes][0][tuner_rental]":
          {
            required: {
              depends: function () {
                if (
                  $(
                    "[id=new_order_new_order_option_info_hikari_tvs_attributes_0_use_1]"
                  ).prop("checked")
                ) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          },
        "new_order[new_order_building_info_attributes][contract_type]": {
          required: true,
        },
        "new_order[new_order_construction_reservation_attributes][pre_construction_reservation_date]":
          {
            required: true,
          },
        "new_order[new_order_construction_reservation_attributes][pre_construction_reservation_time_zone]":
          {
            required: true,
          },
      },
      messages: {
        // "new_order[new_order_construction_reservation_attributes][pre_construction_reservation_time_zone]":
        //   {
        //     required: "事前工事予約日を選択した場合は必須項目です",
        //   },
        // "new_order[new_order_construction_reservation_attributes][prefer_same_day]":
        //   {
        //     required: "事前工事予約日を選択した場合は必須項目です",
        //   },
        "new_order[webbill_id]": {
          webbill_id_19: "19桁の値を入力してください",
        },
        "new_order[new_order_concurrent_construction_attributes][flets_customer_id]":
          {
            required: "同時工事をお申込みの場合は必須項目です",
          },
        "new_order[new_order_concurrent_construction_attributes][current_voip_type]":
          {
            required: "同時工事をお申込みの場合は必須項目です",
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][last_name_kana]":
          {
            required: "選択されたお支払い方法の場合は必須項目です",
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][first_name_kana]":
          {
            required: "選択されたお支払い方法の場合は必須項目です",
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][last_name]":
          {
            required: "選択されたお支払い方法の場合は必須項目です",
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][first_name]":
          {
            required: "選択されたお支払い方法の場合は必須項目です",
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][address_zip_code]":
          {
            required: "選択されたお支払い方法の場合は必須項目です",
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][address_prefecture]":
          {
            required: "選択されたお支払い方法の場合は必須項目です",
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][address_city]":
          {
            required: "選択されたお支払い方法の場合は必須項目です",
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][address_town]":
          {
            required: "選択されたお支払い方法の場合は必須項目です",
          },
        "new_order[new_order_payment_info_attributes][new_order_payment_info_billing_attributes][tel]":
          {
            required: "選択されたお支払い方法の場合は必須項目です",
          },
        "new_order[new_order_option_info_voip_attributes][number_portability0abj_number]":
          {
            required: "番号ポータビリティの場合は必須項目です",
            number_10: "10桁の数字で入力してください",
          },
        "new_order[new_order_option_info_voip_attributes][last_name_kana]": {
          required: "番号ポータビリティの場合は必須項目です",
        },
        "new_order[new_order_option_info_voip_attributes][first_name_kana]": {
          required: "番号ポータビリティの場合は必須項目です",
        },
        "new_order[new_order_option_info_voip_attributes][last_name]": {
          required: "番号ポータビリティの場合は必須項目です",
        },
        "new_order[new_order_option_info_voip_attributes][first_name]": {
          required: "番号ポータビリティの場合は必須項目です",
        },
        "new_order[new_order_option_info_voip_attributes][current_vo_ip_code]":
          {
            required: "番号ポータビリティの場合は必須項目です",
          },
        "new_order[new_order_option_info_voip_attributes][current_vo_ip_subscriber_name_kana]":
          {
            required: "申込者と異なる場合は必須項目です",
          },
        "new_order[new_order_option_info_voip_attributes][current_vo_ip_subscriber_name]":
          {
            required: "申込者と異なる場合は必須項目です",
          },
        "new_order[new_order_option_info_hikari_tvs_attributes][0][plan]": {
          required: "ひかりTVをお申込みの場合は必須項目です",
        },
      },

      errorPlacement: function (error, element) {
        if (
          element.attr("name") ==
          "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][birth_day(1i)]"
        ) {
          error.insertAfter("#validation_place3");
        } else if (
          element.attr("name") ==
          "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][birth_day(2i)]"
        ) {
          error.insertAfter("#validation_place3");
        } else if (
          element.attr("name") ==
          "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][birth_day(3i)]"
        ) {
          error.insertAfter("#validation_place3");
        } else if (
          element.attr("name") ==
          "new_order[new_order_user_info_attributes][new_order_user_info_person_attributes][gender]"
        ) {
          error.insertAfter("#validation_place4");
        } else if (
          element.attr("name") ==
          "new_order[new_order_construction_reservation_attributes][pre_construction_reservation_time_zone]"
        ) {
          error.insertAfter("#validation_place5");
        } else if (
          element.attr("name") ==
          "new_order[new_order_construction_reservation_attributes][prefer_same_day]"
        ) {
          error.insertAfter("#validation_place6");
        } else if (
          element.attr("name") ==
          "new_order[new_order_option_info_voip_attributes][zeroabj_source]"
        ) {
          error.insertAfter("#validation_place7");
        } else if (
          element.attr("name") ==
          "new_order[new_order_option_info_voip_attributes][subscriber_same_flag]"
        ) {
          error.insertAfter("#validation_place8");
        } else if (
          element.attr("name") ==
          "new_order[new_order_option_info_voip_attributes][use]"
        ) {
          error.insertAfter("#validation_place9");
        } else if (
          element.attr("name") ==
          "new_order[new_order_option_info_hikari_tvs_attributes][0][use]"
        ) {
          error.insertAfter("#validation_place10");
        } else if (
          element.attr("name") ==
          "new_order[new_order_concurrent_construction_attributes][construction]"
        ) {
          error.insertAfter("#validation_place11");
        } else if (
          element.attr("name") ==
          "new_order[new_order_option_info_remote_support_attributes][use]"
        ) {
          error.insertAfter("#validation_place12");
        } else if (
          element.attr("name") ==
          "new_order[new_order_option_info_mcafee_security_attributes][use]"
        ) {
          error.insertAfter("#validation_place13");
        } else if (element.attr("name") == "new_order[webbill_status]") {
          error.insertAfter("#validation_place14");
        } else if (element.attr("name") == "new_order[webbill_id_1]") {
          error.insertAfter("#validation_place15");
        } else if (element.attr("name") == "new_order[webbill_id_2]") {
          error.insertAfter("#validation_place15");
        } else if (element.attr("name") == "new_order[webbill_id_3]") {
          error.insertAfter("#validation_place15");
        } else if (element.attr("name") == "new_order[webbill_id_4]") {
          error.insertAfter("#validation_place15");
        } else {
          error.insertAfter(element);
        }
      },
    });
    $("#stop_order_form").validate({
      rules: {
        "stop_order[stop_order_stop_info_attributes][reason]": {
          required: true,
        },
      },
      messages: {
        "stop_order[stop_order_stop_info_attributes][reason]": {
          required: "理由を選択して下さい",
        },
      },
    });
  });