// 2023/07/06 del asakura 万が一混在が発生した時の為の処理らしいので、一旦コメント
// $(function () {
//   $('[name="new_order[webbill_status]"]:radio').change(function () {
//     if ($("[id=new_order_webbill_status_2]").prop("checked")) {
//       $("#new_order_confirm").prop("disabled", true);
//       alert(
//         "現在、大陽日酸エネルギーのご契約者のみ申込みを受け付けております。"
//       );
//     }
//     else {
//       $("#new_order_confirm").prop("disabled", false);
//     }
//   });
// });

$(function () {

  if ($("[id=new_order_webbill_status_1]").prop("checked")) {
    $("tr").removeClass("gas_number");
    $("tr").removeClass("gas_set");
    $("#new_order_is_gas_set_1").prop("disabled", false);
    $(".payment_type").append(
      "<option value='06'>現在ご利用中のガス料金と合算で支払う</option>"
    );
  }

  $('[name="new_order[webbill_status]"]:radio').change(function () {
    if ($("[id=new_order_webbill_status_1]").prop("checked")) {
      $(".payment_type").append(
        "<option value='06'>現在ご利用中のガス料金と合算で支払う</option>"
      );
      $("tr").removeClass("gas_number");
      $("tr").removeClass("gas_set");
      $("#new_order_is_gas_set_1").prop("disabled", false);
    } 
    else if ($("[id=new_order_webbill_status_2]").prop("checked")) {
      $(".payment_type").children("[value=06]").remove();
      $("#new_order_is_gas_set_1").prop("disabled", true);
      $("#new_order_is_gas_set_0").prop("checked", true);
      $(".add_gas_number").addClass("gas_number");
      $(".add_gas_set").addClass("gas_set");
    }
    else {
      $(".add_gas_number").addClass("gas_number");
      $(".add_gas_set").addClass("gas_set");
    }
  });
});

$(function () {
  $('[name="contract_confirm"]:radio').change(function () {
    if ($("[id=no_planned]").prop("checked")) {
      $(".electric_contract").show();
    } else {
      $(".electric_contract").hide();
    }
  });
});

$(function () {
  $('[name="contract_confirm"]:radio').change(function () {
    if ($("[id=no_planned]").prop("checked")) {
      $("#gas_set").prop("disabled", true);
    } else {
      $("#gas_set").prop("disabled", false);
    }
  });
});

$(function () {
  $('[name="contract_confirm_2"]:radio').change(function () {
    if ($("[id=using_2]").prop("checked")) {
      $(".electric_number").css({ display: "contents" });
    } else {
      $(".electric_number").css({ display: "none" });
    }
  });
});

$(function () {
  if (
    $("[id=new_order_new_order_option_info_voip_attributes_use_1]").prop(
      "checked"
    )
  ) {
    $("tr").removeClass("nuro_tel_option");
  }
  $(
    '[name="new_order[new_order_option_info_voip_attributes][use]"]:radio'
  ).change(function () {
    if (
      $("[id=new_order_new_order_option_info_voip_attributes_use_1]").prop(
        "checked"
      )
    ) {
      $("tr").removeClass("nuro_tel_option");
    } else {
      $(".add_nuro_tel_option").addClass("nuro_tel_option");
    }
  });
});

$(function () {
  if (
    $(
      "[id=new_order_new_order_option_info_voip_attributes_option_white_call_1]"
    ).prop("checked")
  ) {
    $("tr").removeClass("white_call_option");
  }
  $(
    '[name="new_order[new_order_option_info_voip_attributes][option_white_call]"]:radio'
  ).change(function () {
    if (
      $(
        "[id=new_order_new_order_option_info_voip_attributes_option_white_call_1]"
      ).prop("checked")
    ) {
      $("tr").removeClass("white_call_option");
    } else {
      $(".add_white_call_option").addClass("white_call_option");
    }
  });
});

$(function () {
  if (
    $(
      "[id=new_order_new_order_concurrent_construction_attributes_construction_1]"
    ).prop("checked")
  ) {
    $("tr").removeClass("concurrent_construction");
  }
  $(
    '[name="new_order[new_order_concurrent_construction_attributes][construction]"]:radio'
  ).change(function () {
    if (
      $(
        "[id=new_order_new_order_concurrent_construction_attributes_construction_1]"
      ).prop("checked")
    ) {
      $("tr").removeClass("concurrent_construction");
    } else {
      $(".add_concurrent_construction").addClass("concurrent_construction");
    }
  });
});

$(function () {
  const selected = $("#concurrent_construction_select").val();
  if (selected === "01") {
    $("tr").removeClass("hikari_denwa_option");
  }
  $("#concurrent_construction_select").change(function () {
    const selected = $("#concurrent_construction_select").val();
    if (selected === "01") {
      $("tr").removeClass("hikari_denwa_option");
    } else {
      $(".add_hikari_denwa_option").addClass("hikari_denwa_option");
    }
  });
});

$(function () {
  const selected = $("#payment_info_select").val();
  if (selected === "04" || selected === "05") {
    $("tr").removeClass("payment_info");
  } 
  $("#payment_info_select").change(function () {
    const selected = $("#payment_info_select").val();
    if (selected != "01") {
      $("tr").removeClass("payment_info");
    } else {
      $(".add_payment_info").addClass("payment_info");
    }
  });
});

$(function () {
  if (
    $(
      "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
    ).prop("checked")
  ) {
    $("tr").removeClass("zeroabj_source");
  }
  $(
    '[name="new_order[new_order_option_info_voip_attributes][zeroabj_source]"]:radio'
  ).change(function () {
    if (
      $(
        "[id=new_order_new_order_option_info_voip_attributes_zeroabj_source_1]"
      ).prop("checked")
    ) {
      $("tr").removeClass("zeroabj_source");
    } else {
      $(".add_zeroabj_source").addClass("zeroabj_source");
    }
  });
});

$(function () {
  if (
    $(
      "[id=new_order_new_order_option_info_voip_attributes_subscriber_same_flag_1]"
    ).prop("checked")
  ) {
    $("tr").removeClass("currnt_vo_ip_subscriber");
  }
  $(
    '[name="new_order[new_order_option_info_voip_attributes][subscriber_same_flag]"]:radio'
  ).change(function () {
    if (
      $(
        "[id=new_order_new_order_option_info_voip_attributes_subscriber_same_flag_1]"
      ).prop("checked")
    ) {
      $("tr").removeClass("currnt_vo_ip_subscriber");
    } else {
      $(".add_currnt_vo_ip_subscriber").addClass("currnt_vo_ip_subscriber");
    }
  });
});

$(function () {
  if (
    $(
      "[id=new_order_new_order_option_info_hikari_tvs_attributes_0_use_1]"
    ).prop("checked")
  ) {
    $("tr").removeClass("hikari_tv_nuro");
  } 
  $(
    '[name="new_order[new_order_option_info_hikari_tvs_attributes][0][use]"]:radio'
  ).change(function () {
    if (
      $(
        "[id=new_order_new_order_option_info_hikari_tvs_attributes_0_use_1]"
      ).prop("checked")
    ) {
      $("tr").removeClass("hikari_tv_nuro");
    } else {
      $(".add_hikari_tv_nuro").addClass("hikari_tv_nuro");
    }
  });
});

$(function () {
  $(
    '[name="cancel_order[cancel_order_quit_info_attributes][quit_type]"]:radio'
  ).change(function () {
    if (
      $("[id=cancel_order_cancel_order_quit_info_attributes_quit_type_1]").prop(
        "checked"
      )
    ) {
      $("tr").removeClass("quit_info");
    } else {
      $(".add_quit_info").addClass("quit_info");
    }
  });
});

$(function () {
  $(
    '[name="cancel_order[cancel_order_retrieve_info_attributes][destination_flg]"]:radio'
  ).change(function () {
    if (
      $(
        "[id=cancel_order_cancel_order_retrieve_info_attributes_destination_flg_1]"
      ).prop("checked")
    ) {
      $("tr").removeClass("retrieve_info");
    } else {
      $(".add_retrieve_info").addClass("retrieve_info");
    }
  });
});

$(function () {
  $(
    '[name="cancel_order[cancel_order_quit_info_attributes][removal]"]:radio'
  ).change(function () {
    if (
      $("[id=cancel_order_cancel_order_quit_info_attributes_removal_1]").prop(
        "checked"
      )
    ) {
      $("tr").removeClass("removal");
    } else {
      $(".add_removal").addClass("removal");
    }
  });
});

$(function () {
  $('input[name="agreement"]').change(function () {
    if (
      $("[id=first_check]").prop("checked") &&
      $("[id=second_check]").prop("checked") &&
      $("[id=third_check]").prop("checked")
    ) {
      $("#new_order_submit").prop("disabled", false);
      $("#new_order_submit").prop("value", "同意して申込む");
    } else {
      $("#new_order_submit").prop("disabled", true);
      $("#new_order_submit").prop("value", "申込みには同意が必要です");
    }
    // if ($(this).is(":checked")) {
    //   $("#new_order_submit").prop("disabled", false);
    // }
    // else {
    //   $("#new_order_submit").prop("disabled", true);
    // } 
  });
});

$(function () {
  $('[name="new_order[new_order_option_info_voip_attributes][option_service_pack]"]:radio').change(function () {
    // ベーシックパック: 申し込む
    if ($("[id=new_order_new_order_option_info_voip_attributes_option_service_pack_05]").prop("checked")) {
      // 「番号表示申込み」を「申込まない」に設定し、非アクティブにする
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_display_0]").prop("checked", true)
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_display_0]").prop("value", "1")
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_display_0]").prop("disabled", true)
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_display_1]").prop("disabled", true)
      // 「番号通知リクエスト」を「通知しない」に設定し、非アクティブにする
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_request_0]").prop("checked", true)
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_request_0]").prop("value", "1")
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_request_0]").prop("disabled", true)
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_request_1]").prop("disabled", true)
    }

    // ベーシックパック: 申し込まない
    if ($("[id=new_order_new_order_option_info_voip_attributes_option_service_pack_00]").prop("checked")) {
      // 「番号表示申込み」をアクティブにする
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_display_0]").prop("disabled", false)
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_display_1]").prop("disabled", false)
      // 「番号通知リクエスト」をアクティブにする
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_request_0]").prop("disabled", false)
      $("[id=new_order_new_order_option_info_voip_attributes_option_number_request_1]").prop("disabled", false)
    }
  });
});