$(function () {
  // エリア検索APIで郵便番号検索
  $('#zip_search').on('click', function () {
    $("#loading").removeClass("loader-bg");
    $("#loading").addClass("add_loader-bg");
    setTimeout(function () {
      $("#loading").removeClass("add_loader-bg");
      $("#loading").addClass("loader-bg");
    }, 1500);
    const href = new URL(window.location.href);
    const url = `${href.origin}/api/service?zip=${$("#zip").val()}&category=g2home`;
  
    $(this).prop('disabled', true);
    $.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      async: false,
      // 取得に成功したときの処理
      success: function (data) {
        response = data
      },
    });
    // 入力された郵便番号に応じたaddresesを配列に入れる
    if (response.result_code != "100") {
      filteredAddresses = response.addresses.filter(
        (address) => address.zip === $("#zip").val()
      );
    }

    // 入力された郵便番号に対応した住所がない時
    if (response.result_code === "100") {
      $(this).prop("disabled", false);
      $("#address_select").children().not(":first").remove();
      $("#plan_detail").addClass("plan_none");
      $("#area_confirm_button").prop("disabled", true);
      return alert("該当する住所が見つかりませんでした");
    };
    // 空の連想配列に郵便番号が一致したaddress_keyと住所を入れる
    const addressHash = {};
    filteredAddresses.forEach((address) => {
      addressHash[address.address_key] = `${address.pref}${address.city}${address.town}`;
      if (Object.keys(addressHash).length) {
        $('#area_seach_address').removeClass('address_none');
      } else {
        $(this).prop('disabled', false);
        $("select#address_key option").remove();
        $("#address_select").children().not(":first").remove();
        $("#plan_detail").addClass("plan_none");
        $("#area_confirm_button").prop("disabled", true);
        return alert('該当する住所が見つかりませんでした');
      };
    });
    // 郵便番号を入力した時にその前に入力されていた場合にリセット
    $('#address_select option:not(:first)').remove();
    // ビューの任意のセレクトフォームにoption要素を追加する
    for (const key in addressHash) {
      const optionElement = document.createElement('option');
      optionElement.value = key;
      optionElement.text = addressHash[key];
      $('#address_select').append(optionElement);
      $(this).prop('disabled', false);
      // $('#area_confirm_button').prop('disabled', false);
    };
  });

  const PlanFunc = function () {
    const href = new URL(window.location.href);
    const url = `${href.origin}/api/service?zip=${$("#zip").val()}&category=g2home`;
  
    $.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      async: false,
      // 取得に成功したときの処理
      success: function (data) {
        response = data
      },
    });

    if ($('#address_select').val() == '') {
      $('#plan_detail').addClass('plan_none');
    } else {
      $("#plan_detail").removeClass("plan_none");
      $('#area_confirm_button').prop('disabled', false);
      // 選択した住所に応じたJSONを取得
      const keyAddress = response.addresses.filter(
        (address) => address.address_key === $("#address_select").val()
      );
      keyAddress.forEach((address) => {
        // 選択した住所の個人のサービス
        const private_services = address.services.filter(
          (service) => service.category === "g2home"
        );
        private_services.forEach((private_service) => {
          if (
            // private_service.state_summary == "providing" ||
            // private_service.state_summary == "accepting"
            private_service.state_summary == "providing"
          ) {
            $("#providing_area").text("");
            $(".batsu").hide();
            $(".answer1").show();
          } else if (
            private_service.state_summary == "providing_partially" 
          ) {
            $("#providing_area").text(
              "同一地域内の一部で提供可能、一部の地域で新規申込受付中または提供予定または未提供"
            );
            $(".batsu").hide();
            $(".answer1").show();
          // } else if (private_service.state_summary == "accepting_partially") {
          //   $("#providing_area").text(
          //     "同一地域内の一部で新規申込受付中、一部の地域で提供予定または未提供"
          //   );
          //   $(".batsu").hide();
          //   $(".answer1").show();
          } else {
            $("#providing_area").text("");
            $(".answer1").hide();
            $(".batsu").show();
            $("#area_confirm_button").prop("disabled", true);
          }
        });
        // 選択した住所をviewに表示
        $("#service_address").text(
          `${address.pref}${address.city}${address.town}`
        );
        $("#service_address_key_value").val(`${address.address_key}`);
        $("#service_pref_value").val(`${address.pref}`);
        $("#service_city_value").val(`${address.city}`);
        $("#service_town_value").val(`${address.town}`);
      });
    };
  };

  // 住所を選択した時
  $('#address_select').on('change', function () {
    PlanFunc();
  });
});